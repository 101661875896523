<template>
  <div class=container>
    <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"><font size="+2"></font></div></td>
          </tr>
          <tr>
            <td><img src="https://cave.cs.columbia.edu/old/databases/rain_streak_db/sharp_small_web_page_match9.gif" width="800" height="400"></td>
          </tr>
          <tr>
            <td><table border="0" cellspacing="6" cellpadding="0">
              <tbody><tr>
                <td width="171" align="left" valign="middle"><a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/coordinate_system.html"></a><a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/coordinate_system.html"><img src="https://cave.cs.columbia.edu/old/databases/rain_streak_db/coord_thumb.gif" width="160" height="120" border="1"></a></td>
                <td width="10" align="left" valign="middle">&nbsp;</td>
                <td width="512" align="left" valign="middle"><strong><a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/coordinate_system.html">Coordinate System used for the Database</a></strong><a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/coordinate_system.html"></a><br>
Please click to see the coordinate system used for the database.&nbsp;</td>
              </tr>
            </tbody></table></td>
          </tr>
          <tr>
            <td>
<strong>About the Database</strong><br>
<br>
The database captures the effects of lighting direction, camera viewing
direction and oscillations parameters on the appearance of rain streaks.
For capturing variation in appearance due to lighting, the elevation
angle of source is varied from -90 degrees to 90 degrees and azimuthal
angle of the source is varied from 10 degrees to 170 degrees both with
steps of 20 degrees. To capture the effects of the viewing direction, we
varied camera elevation angle from 0 degrees to 80 with steps of 20
degrees. For each light and view configuration we render 10 images to
capture the appearance variation due to oscillations. Thus the database
samples the streak appearance in the 4 dimensions.
<br>
<br>
The database streaks are rendered for drop of size 1.6mm and camera
exposure time of 1/60 sec. It also includes streaks rendered under
ambient lighting. The streaks are rendered as 16-bit monochrome images
with maximum resolution of 32 times 1050 pixels. In total, the database
includes 3150 streak images. The database also includes at 3 additional
(lower) resolutions with streak widths of 16, 8 and 4, to avoid
artifacts due to severe down-sampling when rendering streaks far from
the camera. The complete database is about 50 MB in size. The streaks
are in .png format.
<br><br>
<a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/databases.zip">Download the Database<br>
</a>This zip file contains point light
database (point light database) and environment database
(env_light_database).  Each streak is scaled so that its maximum is
65535. For getting the normalized brightness we need to multiply the
streaks with the brightness values given in the txt directories.
<br>
<br>
To download the database for specific resolutions (width of the
rainstreaks):<br>
<a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/size32.zip">32 pixels</a>   
<a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/size16.zip">16 pixels</a>    
<a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/size8.zip">8 pixels</a>    
<a href="https://cave.cs.columbia.edu/old/databases/rain_streak_db/size4.zip">4 pixels</a><br>
<br>
<a href="http://www1.cs.columbia.edu/CAVE/publications/pdfs/Garg_TOG06.pdf">Documentation</a>  (pdf  of the paper)</td>
          </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>